import React from "react";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Header from "../../components/header";

import Tabs from "../../components/tabsWhoWeAre";

const Cenfarte = () => {
  return (
    <Layout>
      <Seo title="Somos Cenfarte" />
      <Header />
      <Tabs selection="consejo" />
      <main className="who-we-are">
        <p>
          Cenfarte está representado por un consejo de administración que, en el
          ejercicio de sus facultades, vela por su buen funcionamiento y el
          interés de todos sus socios de acuerdo a lo establecido en los
          estatutos.
        </p>
        <div className="organisation">
          <div>
            <div className="beautifier"></div>
            <div>
              <p>
                <abbr title="Don">D.</abbr> Ricardo Díaz-Munío Merodio
              </p>
              <p>Presidente</p>
            </div>
          </div>
          <div>
            <div className="beautifier"></div>
            <div>
              <p>
                <abbr title="Don">D.</abbr> Francisco Manuel Porras Fernández
              </p>
              <p>Secretario</p>
            </div>
          </div>
          <div>
            <div className="beautifier"></div>
            <div>
              <p>
                <abbr title="Don">D.</abbr> Andrés Díez Rodríguez
              </p>
              <p>Director gerente</p>
            </div>
          </div>
        </div>

        <div className="organisation">
          <div>
            <div className="beautifier"></div>
            <div>
              <p>
                <abbr title="Doña">Dª.</abbr> Marta Compostizo Catalá
              </p>
              <p>Vocal</p>
            </div>
          </div>
          <div>
            <div className="beautifier"></div>
            <div>
              <p>
                <abbr title="Don">D.</abbr> David Pardo-Vivero Alsina
              </p>
              <p>Vocal</p>
            </div>
          </div>
          <div>
            <div className="beautifier"></div>
            <div>
              <p>
                <abbr title="Doña">Dª.</abbr> María José García Pardo
              </p>
              <p>Vocal</p>
            </div>
          </div>
          <div>
            <div className="beautifier"></div>
            <div>
              <p>
                <abbr title="Doña">Dª.</abbr> Amparo Gobantes S. Emeterio
              </p>
              <p>Vocal</p>
            </div>
          </div>
          <div>
            <div className="beautifier"></div>
            <div>
              <p>
                <abbr title="Don">D.</abbr> Felipe Marañón Canales
              </p>
              <p>Vocal</p>
            </div>
          </div>
          <div>
            <div className="beautifier"></div>
            <div>
              <p>
                <abbr title="Don">D.</abbr> Fernando García Cervera
              </p>
              <p>Vocal</p>
            </div>
          </div>
          <div className="center">
            <div className="beautifier"></div>
            <div>
              <p>
                <abbr title="Don">D.</abbr> Juan Junco Navascues
              </p>
              <p>Vocal</p>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Cenfarte;
