import React from "react";
import { Link } from "gatsby";

const TabsWhoWeAre = (props) => {
  let selected = props.selection;
  return (
    <main className="who-we-are">
      <div className="description">
        <h1>Nosotros</h1>
      </div>
      <div className="selection">
        <Link
          to="/nosotros/historia"
          className={selected === "historia" ? "selected" : ""}
        >
          <h2>Historia</h2>
        </Link>
        <Link
          to="/nosotros/cenfarte"
          className={selected === "consejo" ? "selected" : ""}
        >
          <h2>Consejo de administración</h2>
        </Link>

        <Link
          to="/nosotros/grupo"
          className={selected === "grupo" ? "selected" : ""}
        >
          <h2>Empresas del grupo</h2>
        </Link>
        <Link
          to="/nosotros/cifras"
          className={selected === "cifras" ? "selected" : ""}
        >
          <h2>Cifras</h2>
        </Link>
      </div>
    </main>
  );
};

export default TabsWhoWeAre;
